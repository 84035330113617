<script>
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    props: ["penyusunan_id", "validasi_id", "optionsUser"],
    data() {
        return {
            sessionRoleId: localStorage.session_role_active_id,
            sessionUserId: localStorage.session_user_id,
            sessionPegawaiId: localStorage.session_pegawai_id,
            loadingTable: true,
            detail_penyusunan: [],
            detail_validasi: [],
            optionsDokumen: [],
            list_asesor: [],
            hak_akses: [],
            path_surat_tugas: null,
            formPreviewDocMUK: null,
            modalPreviewDokumenMUK: false,
            // Proses verifikasi
            modal_verifikasi: false,
            catatan: null,
            statusVerifikasi: null,
            // Variable proses Manager Sertifikasi LSP
            checklist: false,
            loading_store_asesor: true,
            modal_pilih_asesor: false,
            data_asesor: [],
            // Proses Ketersediaan Asesor
            status_ketersediaan_asesor: null,
            catatan_ketersediaan: null,
            id_ketersediaan_asesor: null,
            index_ketersediaan_asesor: null,
            showModalStatusKetersediaan: false,
            is_show_dokumen_muk: true,
            // Proses Penerbitan Surat Tugas
            showModalPenerbitanSuratTugas: false,
            qr_path: null,
            // Modal konfirmasi password
            modalPassword: false,
            password: null,
            // Proses pengisian Dokumen
            modalPengisianDokumen: false,
            form_dokumen_muk: null,
            validasi_dokumen: null,
            skk: {
                nama : '',
                url : '',
            },
            skema_sertifikasi_dokumen: {
                nama : '',
                url : '',
            },
            modalSuratTugas: false,

            //modal penolakan
            showModalValidasi: false,
        };
    },
    mounted() {
        this.getDetailValidasi();
    },
    methods: {
        getDetailValidasi() {
            let self = this;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/show-validasi",
                params: {
                    validasi_id: self.validasi_id,
                    penyusunan_id: self.penyusunan_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        console.log(response_data);
                        self.hak_akses = response_data.hak_akses;
                        var data_edit = response_data_fix;
                        self.is_show_dokumen_muk = response_data.is_show_dokumen_muk;
                        self.detail_penyusunan = response_data.data.penyusunan;
                        self.detail_validasi = response_data.data.validasi;
                        self.data_timeline = response_data.data.timeline;
                        self.list_asesor = response_data.data.validasi_muk_asesor;
                        var data_asesor = response_data.data.validasi_muk_asesor;
                        console.log(response_data);
                        self.data_asesor = data_asesor;
                        if (data_asesor) {
                            var data_asesor_raw = [];
                            $.each(data_asesor, function (indexInArray, valueOfElement) {
                                data_asesor_raw.push({
                                    nama_asesor: {
                                        id_asesor: valueOfElement.asesor_id,
                                        nama_asesor: valueOfElement.asesor_nama,
                                        npk: valueOfElement.asesor_npk,
                                        pegawai_id: valueOfElement.pegawai.id,
                                        frekuensi_penyusunan: null,
                                        frekuensi_validasi: null,
                                    },
                                });
                            });
                            self.data_asesor = data_asesor_raw;
                        }

                        var data_dokumen = response_data.data.validasi_dokumen;
                        if (data_dokumen) {
                            let clear_data_data_dokumen = [];
                            $.each(data_dokumen, function (indexInArray, valueOfElement) {
                                clear_data_data_dokumen.push({
                                    dokumen: valueOfElement.dokumen,
                                    catatan: null,
                                    validasi_dokumen: valueOfElement,
                                });
                            });
                            self.optionsDokumen = clear_data_data_dokumen;
                        }
                        self.skk.url = data_edit.skk_url;
                        self.skema_sertifikasi_dokumen.url = data_edit.skema_asertifikasi_dokumen_url;
                        self.skk.nama = data_edit.skk_nama;
                        self.dokumen_all = self.dokumen_isian;
                        self.loadingTable = false;
                        self.loading_store_asesor = false;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        getFormDokumenMUK(index, dokumen_id) {
            let self = this;
            self.formPreviewDocMUK = null;
            var validasi_dokumen = self.optionsDokumen[index];
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/preview-dokumen-muk-validasi", {
                    params: {
                        penyusunan_id: self.penyusunan_id,
                        dokumen_id: dokumen_id,
                        penyusunan_dokumen_id: validasi_dokumen.penyusunan_dokumen_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.formPreviewDocMUK = res_fix.data.pdf;
                    console.log(res_fix);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });

            self.modalPreviewDokumenMUK = true;
        },
        pengisianDokumenMUK(index, dokumen_id, aksi_dokumen) {
            let self = this;

            self.form_dokumen_muk = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            self.modalPengisianDokumen = true;
            var validasi_dokumen = self.optionsDokumen[index];
            console.log(validasi_dokumen);
            self.validasi_dokumen = validasi_dokumen;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/get-form-dokumen-muk-validasi", {
                    params: {
                        penyusunan_id: self.detail_penyusunan.id,
                        penyusunan_dokumen_id: validasi_dokumen.penyusunan_dokumen_id,
                        validasi_dokumen_id: validasi_dokumen.id,
                        dokumen_id: dokumen_id,
                        aksi_dokumen: aksi_dokumen,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    // var res_fix = response.data.data;
                    console.log(response.data.html);
                    self.form_dokumen_muk = response.data.html;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        storeInputDokumenMUK() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            var form = $("#form-dokumen-muk-validasi");
            console.log(self.validasi_dokumen);
            var mydata = new FormData(form[0]);
            mydata.append("penyusunan_dokumen_id", self.validasi_dokumen?.validasi_dokumen.penyusunan_dokumen_id);
            mydata.append("validasi_dokumen_id", self.validasi_dokumen?.validasi_dokumen?.id);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/store-form-dokumen-muk-validasi", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data;
                    console.log(res_data);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Pengisian dokumen MUK berhasil disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    self.modalPengisianDokumen = false;
                    self.getDetailValidasi();
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        prosesVerifikasi(status) {
            let self = this;
            self.statusVerifikasi = status;
            if (self.detail_validasi?.status_id == "d6520e74-3c0f-11ee-a5ac-0242ac110009") {
                self.modalPassword = true;
                self.showModalPenerbitanSuratTugas = false;
            }else if (status == "tolak") {
                self.showModalValidasi = true;
                self.statusVerifikasi = status;
            }else if (status == "pilih-asesor") {
                self.modal_pilih_asesor = true;
                self.storeAsesorValidasi();
            }else{
                self.statusVerifikasi = status;
                self.prosesPerubahanStatus();
                // self.modal_verifikasi = true;
            }
            // if (self.detail_validasi?.status_id == "b5efdfa8-3c0f-11ee-a5ac-0242ac110009") {
            //     this.storeAsesorValidasi();
            // }
        },
        prosesPerubahanStatus() {
            let self = this;
            if (!self.statusVerifikasi) {
                self.statusVerifikasi = "terima";
            }
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/ubah-status-validasi",
                data: {
                    id: self.detail_validasi.id,
                    status: self.statusVerifikasi,
                    catatan: self.catatan,
                    password: self.password,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list validasi muk segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.modal_verifikasi = false;
                            self.modal_verifikasi = false;
                            self.showModalValidasi = false,
                            self.modal_pilih_asesor = false;
                            self.showModalStatusKetersediaan = false;
                            self.showModalPenerbitanSuratTugas = false;
                            self.modalPassword = false;
                            self.$router.push({ name: "all-daftar_muk-validasi" });
                        }
                    });
                })
                .catch(function (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    // Swal.close();
                });
        },
        // Function Proses Manager Sertifikasi LSP
        addAsesor() {
            this.data_asesor.push({
                nama_asesor: null,
            });
        },
        removeAsesor(index) {
            this.data_asesor.splice(index, 1);
            this.storeAsesorValidasi();
        },
        storeAsesorValidasi() {
            let self = this;
            self.loading_store_asesor = true;
            self.path_surat_tugas = null;
            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/store-asesor-validasi",
                data: {
                    id: self.detail_validasi.id,
                    asesor_list: JSON.stringify(self.data_asesor),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    var res_fix = response.data;
                    console.log(res_fix);
                    self.path_surat_tugas = res_fix.pdf;
                    self.loading_store_asesor = false;
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // Proses Ketersediaan Asesor
        verifikasiKetersediaan(status_ketersediaan, id_item, index) {
            let self = this;
            self.status_ketersediaan_asesor = status_ketersediaan;
            self.id_ketersediaan_asesor = id_item;
            self.index_ketersediaan_asesor = index;
            if (status_ketersediaan == "Tidak Bersedia") {
                self.showModalStatusKetersediaan = true;
            } else {
                self.prosesVerifikasiKetersediaan();
            }
        },
        prosesVerifikasiKetersediaan() {
            let self = this;
            self.showModalStatusKetersediaan = false;
            var index = self.index_ketersediaan_asesor;
            var id_ketersediaan_asesor = self.id_ketersediaan_asesor;
            var keterangan_ketersediaan = self.status_ketersediaan_asesor;
            self.list_asesor[index].is_loading = true;
            axios
                .put(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/ubah-status-ketersediaan-asesor-validator",
                    {
                        validasi_muk_id: self.detail_validasi.id,
                        id_ketersediaan_asesor: id_ketersediaan_asesor,
                        keterangan_ketersediaan: keterangan_ketersediaan,
                        notes: self.catatan_ketersediaan,
                        penyusunan_muk_id: self.id,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.list_asesor[index].is_loading = false;
                    self.list_asesor[index] = res_fix.penyusunan_muk_asesor;
                    self.pdf_surat_tugas = res_fix.pdf?.pdf;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list penyusunan muk segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.modal_verifikasi = false;
                            self.modal_pilih_asesor = false;
                            self.showModalStatusKetersediaan = false;
                            self.showModalPenerbitanSuratTugas = false;
                            self.$router.push({ name: "all-daftar_muk-validasi" });
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        // Proses persetujuan ketua LSP
        terbitkanSuratTugas() {
            let self = this;
            self.showModalPenerbitanSuratTugas = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.path_surat_tugas = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/generate-surat-tugas-validasi", {
                    params: {
                        id: self.detail_validasi.id,
                        dokumen_surat_tugas: "validasi",
                        status_dokumen: "fix",
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.qr_path = res_fix.qr;
                    self.path_surat_tugas = res_fix.pdf;
                    console.log(self.qr_path);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        modalPasswordShow() {
            this.modalPassword = true;
            this.showModalPenerbitanSuratTugas = false;
        },
        getPreviewSKK(){
            let self = this;   
            self.formPreviewDocMUK = self.skk.url;
            self.modalPreviewDokumenMUK = true;
        },
        getPreviewSkemaSertifikasi(){
            let self = this;   
            self.formPreviewDocMUK = self.skema_sertifikasi_dokumen.url;
            self.modalPreviewDokumenMUK = true;
        },
        
        previewSuratTugasAsesor() {
            this.modalSuratTugas = true;
            var path_surat_tugas = process.env.VUE_APP_BACKEND_URL + "/" + this.detail_validasi.path_surat_tugas;
            this.path_surat_tugas = path_surat_tugas;
        },
        terbitkanSuratTugasKetua(){
            this.modalPassword = true;
            this.showModalPenerbitanSuratTugas = false;
        }
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-bordered table-striped">
                <tbody>
                    <tr>
                        <td style="width: 350px"><strong>Nomor Surat Tugas Validasi</strong></td>
                        <td>: {{ detail_validasi.surat_validasi_nomor }}</td>
                    </tr>
                    <tr>
                        <td><strong>Tanggal Surat Tugas Validasi</strong></td>
                        <td>: {{ detail_validasi.surat_validasi_tanggal }}</td>
                    </tr>
                    <tr>
                        <td><strong>Perihal</strong></td>
                        <td>: {{ detail_validasi.perihal }}</td>
                    </tr>
                    <tr>
                        <td><strong>Skema Sertifikasi</strong></td>
                        <td>: {{ detail_validasi.skema_code }} - {{ detail_validasi.skema_name }}</td>
                    </tr>
                    <tr>
                        <td><strong>Metode Sertifikasi</strong></td>
                        <td>: {{ detail_validasi.metode_sertifikasi_nama }}</td>
                    </tr>
                    <tr>
                        <td><strong>Status</strong></td>
                        <td>: {{ detail_validasi.status_nama }}</td>
                    </tr>
                    <tr v-if="detail_validasi?.nomor_pengesahan">
                        <td><strong>Nomor Pengesahan</strong></td>
                        <td>: {{ detail_validasi.nomor_pengesahan }}</td>
                    </tr>
                    <tr v-if="detail_validasi?.tanggal_pengesahan">
                        <td><strong>Tanggal Pengesahan</strong></td>
                        <td>: {{ detail_validasi.tanggal_pengesahan }}</td>
                    </tr>
                    <tr v-if="detail_validasi?.pengesah_nama">
                        <td><strong>Disahkan Oleh</strong></td>
                        <td>: {{ detail_validasi.pengesah_nama }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-3" v-if="data_asesor.length > 0">
        <div>
            <h5>Daftar Asesor Validator</h5>
        </div>
        <div class="form-group col-md-12">
            <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                <thead class="bg-dark text-center text-white">
                    <tr>
                        <th style="width: 5%">No</th>
                        <th>Nama Asesor Validator</th>
                        <th>Status Ketersediaan</th>
                        <th>Surat Tugas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loadingTable">
                        <td colspan="3"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                    </tr>
                    <tr v-else-if="data_asesor.length == 0">
                        <td colspan="4">Asesor Tidak Tersedia</td>
                    </tr>
                    <tr v-else v-for="(item, index) in list_asesor" :key="index">
                        <td class="text-center">
                            {{ index + 1 }}
                        </td>
                        <td>
                            {{ item.asesor_npk }} - {{ item.asesor_nama }}
                        </td>
                        <td>
                            <div v-if="item.asesor_id == sessionPegawaiId || sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070'">
                                <div v-if="item?.is_loading"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                                <div class="btn-group" v-else-if="item.keterangan_ketersediaan == 'Bersedia'">
                                    <b-button v-if="detail_validasi.status_id == '250c4dd4-3feb-11ee-94de-0242ac110009'" v-on:click="verifikasiKetersediaan('Roleback', item.id, index)" type="button" variant="warning" class="btn-sm"
                                        ><i class="bx bx-loader"></i>

                                        Roleback</b-button
                                    >
                                    <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                </div>
                                <div class="btn-group" v-else-if="item.keterangan_ketersediaan == 'Tidak Bersedia'">
                                    <b-button v-if="detail_validasi.status_id == '250c4dd4-3feb-11ee-94de-0242ac110009'" v-on:click="verifikasiKetersediaan('Roleback', item.id, index)" type="button" variant="warning" class="btn-sm"><i class="bx bx-loader"></i> Roleback</b-button>
                                    <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                </div>
                                <div class="btn-group" v-else>
                                    <div v-if="detail_validasi.status_id == '250c4dd4-3feb-11ee-94de-0242ac110009'">
                                        <b-button v-on:click="verifikasiKetersediaan('Tidak Bersedia', item.id, index)" type="button" variant="danger" class="btn-sm"><i class="fa fa-times"></i> Tidak Bersedia</b-button>
                                        <b-button v-on:click="verifikasiKetersediaan('Bersedia', item.id, index)" type="button" variant="success" class="btn-sm"><i class="fa fa-check"></i> Bersedia</b-button>
                                    </div>
                                </div>
                                <div v-if="item.keterangan_ketersediaan == 'Tidak Bersedia' && item.notes">
                                    Alasan Tidak Bersedia: {{ item.notes }}
                                </div>
                            </div>
                            <div v-else>
                                <div class="btn-group" v-if="item.keterangan_ketersediaan == 'Bersedia'">
                                    <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                </div>
                                <div class="btn-group" v-else-if="item.keterangan_ketersediaan == 'Tidak Bersedia'">
                                    <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                </div>
                                <div class="btn-group" v-else></div>
                                <div v-if="item.keterangan_ketersediaan == 'Tidak Bersedia' && item.notes">
                                    <br />
                                    Alasan Tidak Bersedia: {{ item.notes }}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="btn btn-info btn-sm" v-on:click="previewSuratTugasAsesor" v-if="detail_validasi.path_surat_tugas"><i class="fa fa-eye"></i> Lihat Surat Tugas</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" v-if="detail_validasi.status_id != '250c4dd4-3feb-11ee-94de-0242ac110009'">
        <div>
            <h5>Daftar Dokumen MUK</h5>
            <div class="alert alert-info" v-if="!is_show_dokumen_muk"><i class="fa fa-info"></i> Dokumen MUK akan muncul setelah asesor bersedia</div>
        </div>
        <table class="table mb-0 table-bordered table-condensed mt-2" v-if="is_show_dokumen_muk">
            <thead class="bg-dark text-center text-white">
                <tr>
                    <th>No</th>
                    <th>Dokumen</th>
                    <th>Aksi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="loadingTable">
                    <td colspan="3"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                </tr>
                <tr v-else-if="optionsDokumen.length == 0">
                    <td colspan="4">Dokumen Tidak Tersedia</td>
                </tr>
                <tr v-else v-for="(item, index) in optionsDokumen" :key="index">
                    <td>
                        {{ index + 1 }}
                    </td>
                    <td>
                        {{ item.dokumen?.nama_dokumen_muk }}
                    </td>
                    <td v-if="detail_validasi?.status_id == 'c4ed2678-3c0f-11ee-a5ac-0242ac110009'" v-bind:class="{
                        'bg-warning': item.dokumen?.status_proses_validasi_temuan == detail_validasi.status_id && hak_akses && item.validasi_dokumen.status_pengisian != 'validasi-muk',
                        'bg-success': item.dokumen?.status_proses_validasi_temuan == detail_validasi.status_id && hak_akses && item.validasi_dokumen.status_pengisian == 'validasi-muk',
                    }">
                        <button v-if="item.dokumen?.id == 'b88cad66-39dc-11ee-9a36-0242ac110009' || item.dokumen?.id == '4c940cac-3bae-11ee-8a7e-0242ac110009' || item.dokumen?.id == '4c940cac-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSKK()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                        <button v-else-if="item.dokumen?.id == '1b5dd154-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSkemaSertifikasi()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                        <button v-else type="button" v-on:click="getFormDokumenMUK(index, item.dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>

                        <!-- <button type="button" v-on:click="getFormDokumenMUK(index, item.dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button> -->

                        <button type="button" v-on:click="pengisianDokumenMUK(index, item.dokumen.id, 'pengisian-temuan')" class="btn btn-primary btn-sm m-1 rounded-0" v-if="item.dokumen?.status_proses_validasi_temuan == detail_validasi.status_id && hak_akses"><i class="fa fa-eye"></i> Lengkapi Dokumen</button>
                        <button type="button" v-on:click="pengisianDokumenMUK(index, item.dokumen.id, 'pengisian-tindak-lanjut')" class="btn btn-primary btn-sm m-1 rounded-0" v-if="item.dokumen?.status_proses_tindak_lanjut == detail_validasi.status_id && hak_akses"><i class="fa fa-eye"></i> Lengkapi Tindak Lanjut Temuan</button>
                        <router-link v-if="detail_validasi.status_id == 'cf3ec6c2-3c0f-11ee-a5ac-0242ac110009' && hak_akses && item.dokumen?.status_proses_validasi_temuan != 'cf3ec6c2-3c0f-11ee-a5ac-0242ac110009'"
                            :to="{
                                name: 'edit-konfigurasi-penyusunan-muk',
                                params: { id: item.dokumen.id, id_penyusunan: penyusunan_id },
                            }"
                            class="btn btn-danger btn-sm"
                            ><i class="fa fa-edit"></i> Perbaiki Dokumen</router-link
                        >
                    </td>
                    <td v-else-if="detail_validasi?.status_id == 'cf3ec6c2-3c0f-11ee-a5ac-0242ac110009'" v-bind:class="{
                        'bg-success': item.dokumen?.status_proses_tindak_lanjut == detail_validasi.status_id && hak_akses && item.validasi_dokumen.status_pengisian == 'tindak-lanjut-validasi-muk',
                        'bg-warning': item.dokumen?.status_proses_tindak_lanjut == detail_validasi.status_id && hak_akses && item.validasi_dokumen.status_pengisian != 'tindak-lanjut-validasi-muk',
                    }">
                        <button v-if="item.dokumen?.id == 'b88cad66-39dc-11ee-9a36-0242ac110009' || item.dokumen?.id == '4c940cac-3bae-11ee-8a7e-0242ac110009' || item.dokumen?.id == '4c940cac-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSKK()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                        <button v-else-if="item.dokumen?.id == '1b5dd154-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSkemaSertifikasi()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                        <button v-else type="button" v-on:click="getFormDokumenMUK(index, item.dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>

                        <!-- <button type="button" v-on:click="getFormDokumenMUK(index, item.dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button> -->

                        <button type="button" v-on:click="pengisianDokumenMUK(index, item.dokumen.id, 'pengisian-temuan')" class="btn btn-primary btn-sm m-1 rounded-0" v-if="item.dokumen?.status_proses_validasi_temuan == detail_validasi.status_id && hak_akses"><i class="fa fa-eye"></i> Lengkapi Dokumen</button>
                        <button type="button" v-on:click="pengisianDokumenMUK(index, item.dokumen.id, 'pengisian-tindak-lanjut')" class="btn btn-primary btn-sm m-1 rounded-0" v-if="item.dokumen?.status_proses_tindak_lanjut == detail_validasi.status_id && hak_akses"><i class="fa fa-eye"></i> Lengkapi Tindak Lanjut Temuan</button>
                        <router-link v-if="detail_validasi.status_id == 'cf3ec6c2-3c0f-11ee-a5ac-0242ac110009' && hak_akses && item.dokumen?.status_proses_validasi_temuan != 'cf3ec6c2-3c0f-11ee-a5ac-0242ac110009'"
                            :to="{
                                name: 'edit-konfigurasi-penyusunan-muk',
                                params: { id: item.dokumen.id, id_penyusunan: penyusunan_id },
                            }"
                            class="btn btn-danger btn-sm"
                            ><i class="fa fa-edit"></i> Perbaiki Dokumen</router-link
                        >
                    </td>
                    <td v-else>
                        <button v-if="item.dokumen?.id == 'b88cad66-39dc-11ee-9a36-0242ac110009' || item.dokumen?.id == '4c940cac-3bae-11ee-8a7e-0242ac110009' || item.dokumen?.id == '4c940cac-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSKK()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                        <button v-else-if="item.dokumen?.id == '1b5dd154-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSkemaSertifikasi()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                        <button v-else type="button" v-on:click="getFormDokumenMUK(index, item.dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>

                        <!-- <button type="button" v-on:click="getFormDokumenMUK(index, item.dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button> -->

                        <button type="button" v-on:click="pengisianDokumenMUK(index, item.dokumen.id, 'pengisian-temuan')" class="btn btn-primary btn-sm m-1 rounded-0" v-if="item.dokumen?.status_proses_validasi_temuan == detail_validasi.status_id && hak_akses"><i class="fa fa-eye"></i> Lengkapi Dokumen</button>
                        <button type="button" v-on:click="pengisianDokumenMUK(index, item.dokumen.id, 'pengisian-tindak-lanjut')" class="btn btn-primary btn-sm m-1 rounded-0" v-if="item.dokumen?.status_proses_tindak_lanjut == detail_validasi.status_id && hak_akses"><i class="fa fa-eye"></i> Lengkapi Tindak Lanjut Temuan</button>
                        <router-link v-if="detail_validasi.status_id == 'cf3ec6c2-3c0f-11ee-a5ac-0242ac110009' && hak_akses && item.dokumen?.status_proses_validasi_temuan != 'cf3ec6c2-3c0f-11ee-a5ac-0242ac110009'"
                            :to="{
                                name: 'edit-konfigurasi-penyusunan-muk',
                                params: { id: item.dokumen.id, id_penyusunan: penyusunan_id },
                            }"
                            class="btn btn-danger btn-sm"
                            ><i class="fa fa-edit"></i> Perbaiki Dokumen</router-link
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- Section Aksi Per Role -->
    <hr />
    <div class="row" v-if="hak_akses">
        <div class="" v-if="detail_validasi?.status_id == '2d1b9b0e-3c0f-11ee-a5ac-0242ac110009'">
            <div class="col-md-12 col-md-6">
                <div class="text-end">
                    <b-button v-on:click="submitKetuaLSP" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Submit</b-button>
                </div>
            </div>
        </div>
        <div class="" v-else>
            <div v-if="detail_validasi?.status?.slug != 'validasi_muk_selesai'">
                <div v-if="detail_validasi?.next_status?.slug == 'validasi_muk_selesai'">
                    <div class="text-end">
                        <b-button v-on:click="submitNextStatus()" type="button" variant="success" class="m-1 btn-sm"><i class="fa fa-save"></i> Selesaikan</b-button>
                    </div>
                </div>

                <div v-else-if="detail_validasi.status_id == 'b5efdfa8-3c0f-11ee-a5ac-0242ac110009'">
                    <div class="text-end">
                        <!-- <b-button v-on:click="prosesVerifikasi('tolak')" type="button" variant="danger" class="m-1 btn-sm"><i class="fa fa-times"></i> Tolak</b-button> -->
                        <b-button v-on:click="prosesVerifikasi('pilih-asesor')" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                    </div>
                </div>
                <!-- status Proses Verifikasi Ketersediaan Asesor Validator -->
                <div v-else-if="detail_validasi.status_id == '250c4dd4-3feb-11ee-94de-0242ac110009'">&nbsp;</div>
                <!-- Status Menunggu Persetujuan Ketua LSP -->
                <div v-else-if="detail_validasi.status_id == 'be950d68-3c0f-11ee-a5ac-0242ac110009'">
                    <div class="text-end">
                        <b-button v-on:click="terbitkanSuratTugas()" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Persetujuan Ketua LSP</b-button>
                    </div>
                </div>
                <!-- status Submit penjamin -->
                <div v-else-if="detail_validasi.status_id == 'dedce3d4-3c0f-11ee-a5ac-0242ac110009'">
                    <div class="text-end">
                        <b-button v-on:click="modalPasswordShow()" type="button" variant="success" class="m-1 btn-sm"><i class="fa fa-check"></i> Pengesahan MUK</b-button>                    
                    </div>
                </div>
                <div v-else-if="detail_validasi.status_id == 'aca2eca6-3c0f-11ee-a5ac-0242ac110009'">
                    <div class="text-end">
                        <b-button v-on:click="prosesVerifikasi('terima')" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>                    
                    </div>
                </div>
                <div v-else>
                    <div class="text-end" v-if="detail_validasi.status_id == 'd6520e74-3c0f-11ee-a5ac-0242ac110009'">
                        <b-button v-on:click="prosesVerifikasi('tolak')" type="button" variant="danger" class="m-1 btn-sm"><i class="fa fa-times"></i> Tolak</b-button>
                        <b-button v-on:click="prosesVerifikasi('terima')" type="button" variant="success" class="m-1 btn-sm"><i class="fa fa-check"></i> Terima </b-button>
                    </div>
                    <div class="text-end" v-else-if="detail_validasi.status_id != 'e528a2f0-3c0f-11ee-a5ac-0242ac110009'">
                        <!-- <b-button v-on:click="prosesVerifikasi('tolak')" type="button" variant="danger" class="m-1 btn-sm"><i class="fa fa-times"></i> Tolak</b-button> -->
                        <b-button v-on:click="prosesVerifikasi('terima')" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal v-model="modal_verifikasi" title="Proses Verifikasi" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <label for="">Catatan</label>
                <textarea v-model="catatan" id="" cols="30" rows="5" class="form-control"></textarea>
            </div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="prosesPerubahanStatus()"><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="modal_pilih_asesor" title="Proses Verifikasi" size="lg" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group" v-if="detail_validasi?.status_id == 'b5efdfa8-3c0f-11ee-a5ac-0242ac110009'">
                <label for="">Pilih Asesor Validator</label>
                <table class="table table-bordered">
                    <thead class="bg-dark text-light">
                        <tr>
                            <td>Nama Asesor Validator</td>
                            <td style="width: 50px">
                                <div class="btn btn-success btn-sm" v-on:click="addAsesor"><i class="bx bx-plus"></i></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="data_asesor?.length == 0">
                            <td colspan="2">Data Tidak Tersedia</td>
                        </tr>
                        <tr v-else v-for="(val, key) in data_asesor" :key="key">
                            <td>
                                <v-select :options="optionsUser" label="nama_asesor" v-model="val.nama_asesor" placeholder="Pilih Asesor" @update:modelValue="storeAsesorValidasi()">
                                    <template v-slot:option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.frekuensi_validasi }} Frekuensi Validator</template>
                                    <template v-slot:selected-option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.frekuensi_validasi }} Frekuensi Validator</template>
                                </v-select>
                            </td>
                            <td>
                                <div class="btn btn-danger btn-sm" v-on:click="removeAsesor(key)"><i class="bx bx-minus"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="form-group">
                    <div v-if="loading_store_asesor"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                    <iframe v-bind:src="path_surat_tugas" width="100%" height="400" v-else></iframe>
                </div>
                <div class="form-group"><input type="checkbox" v-model="checklist" /> Apakah Anda yakin? asesor yang dipilih akan segera mendapat notifikasi validasi MUK</div>
            </div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="prosesPerubahanStatus()" v-if="checklist"><i class="fas fa-save"></i> Simpan</button>
                <button class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalStatusKetersediaan" title="Alasan Tidak Bersedia" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="prosesVerifikasiKetersediaan">
                <div class="form-group">
                    <!-- <label for="">Alasan Penolakan</label> -->
                    <textarea v-model="catatan_ketersediaan" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPenerbitanSuratTugas" title="Preview Surat Tugas Validasi MUK" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <td style="width: 350px"><strong>Nomor Surat Tugas</strong></td>
                                    <td>: {{ detail_validasi.surat_validasi_nomor }}</td>
                                    <td rowspan="2" v-if="qr_path" style="width: 150px">
                                        <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Tanggal Surat Tugas</strong></td>
                                    <td>: {{ detail_validasi.surat_validasi_tanggal }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group">
                    <iframe v-bind:src="path_surat_tugas" width="100%" height="400"></iframe>
                </div>
                <div class="form-group"><input type="checkbox" v-model="checklist" /> Apakah Anda yakin ingin menerbitkan surat tugas tersebut?</div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="showModalPenerbitanSuratTugas = false"><i class="fas fa-times"></i> Tutup</button>
                    <button type="button" class="btn btn-primary mt-3" v-if="checklist" @click="terbitkanSuratTugasKetua"><i class="fas fa-save"></i> Terbitkan</button>
                    <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Terbitkan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="modalPassword" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="prosesPerubahanStatus">
                <div class="form-group">
                    <!-- <label for="">Alasan Penolakan</label> -->
                    <input type="password" required class="form-control" v-model="password" placeholder="Password Anda" />
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3" type="submit"><i class="fas fa-save"></i> Konfirmasi</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="modalPreviewDokumenMUK" title="Preview Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <form @submit.prevent="StorePreviewMUK">
                <iframe v-bind:src="formPreviewDocMUK" width="100%" height="400"></iframe>
                <div class="card-footer text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="modalPreviewDokumenMUK = false"><i class="fas fa-times"></i> Tutup</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="modalPengisianDokumen" title="Pengisian Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <form id="form-dokumen-muk-validasi">
                <div class="form-group">
                    <div v-html="form_dokumen_muk"></div>
                </div>
            </form>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="storeInputDokumenMUK"><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="modalSuratTugas" title="Preview Surat Tugas" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <iframe v-bind:src="path_surat_tugas" width="100%" height="400"></iframe>
            </div>
            <div class="form-group text-end">
                <button type="button" class="btn btn-secodary mt-3" @click="modalSuratTugas = false"><i class="fas fa-times"></i> Tutup</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalValidasi" title="Alasan Penolakan" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="prosesPerubahanStatus">
                <div class="form-group">
                    <!-- <label for="">Alasan Penolakan</label> -->
                    <textarea v-model="catatan" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
</template>
