<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import moment from "moment";
import detail_validasi from "@/router/views/validasi-muk/detail-validasi";

export default {
    page: {
        title: "Detail Materi Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        detail_validasi,
    },
    data() {
        return {
            title: "Detail Materi Uji Kompetensi",
            items: [
                {
                    text: "Validasi MUK",
                    href: "/validasi-muk",
                },
                {
                    text: "Detail Materi Uji Kompetensi",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            sessionRoleId: localStorage.session_role_active_id,
            sessionUserId: localStorage.session_user_id,
            sessionPegawaiId: localStorage.session_pegawai_id,
            id: this.$route.params.id,
            data_penyusunan: [],
            data_asesor: [],
            data_timeline: [],
            optionsDokumen: [],
            next_status: [],
            loadingTable: true,
            hak_akses: false,

            modalPreviewDokumenMUK: false,
            showModalValidasi: false,
            showModalAsesor: false,
            formPreviewDocMUK: null,
            statusValidasi: null,

            optionsUser: [],

            // data modal pemilihan asesor
            asesor_list: [],
            iframe_pdf_surat_tugas: null,
            loading_store_asesor: true,
            disclaimer_asesor_penyusun: false,

            //data modal kehadiran asesor
            status_kehadiran_asesor: null,
            id_kehadiran_asesor: null,
            index_kehadiran_asesor: null,
            catatan_kehadiran: null,
            showModalStatusKehadiran: null,
            pdf_surat_tugas: null,
            is_show_dokumen_muk: true,

            //submit ketua LSP
            showModalSubmitKetuaLSP: false,
            backend_url: process.env.VUE_APP_BACKEND_URL,
            disclaimer_surat_tugas: false,
            qr_path: null,
            iframe_pdf_surat_tugas_fix: null,

            // Proses pengisian Dokumen
            modalPengisianDokumen: false,
            form_dokumen_muk: null,
            penyusunan_dokumen: null,
            jumlah_dokumen_isi: 0,
            jumlah_dokumen_wajib_isi: 0,

            // modal
            modalPasswordKetuaLSP: false,
            password: null,
            modalSuratTugas: false,
            path_surat_tugas: null,
            preview_surat_tugas: null,
            modalPassword: false,
            skk: {
                nama: "",
                url: "",
            },
            skema_sertifikasi_dokumen: {
                nama: "",
                url: "",
            },
            dokumen_all: null,
            dokumen_isian: [
                // "b88c817e-39dc-11ee-9a36-0242ac110009", // FR.VA Pra Assessment (Memberikan Kontribusi dalam validasi Assessment)
                // "b88c9006-39dc-11ee-9a36-0242ac110009", // FR.IA.11 (Ceklis Meninjau Instrumen Assessment)
                // "b88c93e4-39dc-11ee-9a36-0242ac110009", // Ceklis Konsultasi Pra Assessment
                "6a00c792-3ab6-11ee-92d6-0242ac110009", // FR.MAPA.01 Observasi (Merencanakan Aktivitas dan Proses Asesmen)
                "b88ca2a8-39dc-11ee-9a36-0242ac110009", // FR.MAPA.01 Portofolio (Merencanakan Aktivitas dan Proses Asesmen)
                // "b88ca64a-39dc-11ee-9a36-0242ac110009", // FR.MAPA.02 (Peta Instrument Assessment hasil pendekatan assessment dan perencanaan Assessment)
                // "b88cb158-39dc-11ee-9a36-0242ac110009", // FR.AK.04 (Banding Assessment)
                // "b88cb55e-39dc-11ee-9a36-0242ac110009", // FR.AK.01 (Persetujuan Assesment dan Kerahasiaan)
                // "b88cd214-39dc-11ee-9a36-0242ac110009", // FR.IA.08 (Ceklis Verifikasi Portofolio)
                "b88cd5ac-39dc-11ee-9a36-0242ac110009", // FR.IA.09 (Pertanyaan Wawancara)
                // "b88cc026-39dc-11ee-9a36-0242ac110009", // FR.IA.10 (Klarifikasi Bukti Pihak Ketiga)
                // "d3edc0c2-3b1c-11ee-a9ed-0242ac110009", // FR.VA Sesaat Asesmen
                // "b88c979a-39dc-11ee-9a36-0242ac110009", // FR.AK.02 REKAMAN ASESMEN
                // "b88ce114-39dc-11ee-9a36-0242ac110009", // FR.AK.03 (Umpan Balik dan Catatan Assessment)
                // "b88ce83a-39dc-11ee-9a36-0242ac110009", // FR.AK.06 (Meninjau Proses Assessment)
                // "b88cf15e-39dc-11ee-9a36-0242ac110009", // FR.VA Pasca Assessment (Memberikan Kontribusi dalam validasi Assessment)
                "b88cb90a-39dc-11ee-9a36-0242ac110009", // FR.IA.01 (Ceklis Observasi Aktivitas di Tempat Kerja atau Tempat Kerja Simulasi)
                "b88cbc8e-39dc-11ee-9a36-0242ac110009", // FR.IA.02 (Tugas Praktek Demonstrasi)
                "b88cc026-39dc-11ee-9a36-0242ac110009", // FR.IA.03 (Pertanyaan Untuk Mendukung Observasi)
                "b88cce68-39dc-11ee-9a36-0242ac110009", // FR.IA.07 (Pertanyaan Lisan)
            ],
        };
    },
    mounted() {
        let loadInputScript = document.createElement("script");
        loadInputScript.setAttribute("src", process.env.VUE_APP_BACKEND_URL + "/js/input.js");
        document.head.appendChild(loadInputScript);
        this.getDetailPenyusunan();
        this.getUser();
    },
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        },
        getUser() {
            let self = this;
            // load data
            var config_user = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-asesor",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_user)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsUser = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDetailPenyusunan() {
            let self = this;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/show",
                params: {
                    id: self.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        var data_edit = response_data_fix;
                        self.data_penyusunan = data_edit;
                        var data_dokumen = data_edit.penyusunan_muk_dokumen;
                        var data_asesor = data_edit.penyusunan_muk_asesor;
                        self.hak_akses = response_data.hak_akses;
                        self.is_show_dokumen_muk = response_data.is_show_dokumen_muk;
                        self.jumlah_dokumen_isi = response_data_fix.jumlah_dokumen_isi;
                        console.log(response_data);
                        self.data_asesor = data_asesor;
                        if (data_asesor) {
                            var asesor_list = [];
                            $.each(data_asesor, function (indexInArray, valueOfElement) {
                                asesor_list.push({
                                    nama_asesor: {
                                        id_asesor: valueOfElement.asesor_id,
                                        nama_asesor: valueOfElement.asesor_nama,
                                        npk: valueOfElement.asesor_npk,
                                        pegawai_id: valueOfElement.pegawai.id,
                                        frekuensi_penyusunan: null,
                                        frekuensi_validasi: null,
                                    },
                                });
                            });
                            self.asesor_list = asesor_list;
                        }

                        self.loadingTable = false;
                        self.data_timeline = data_edit.penyusunan_muk_timeline;
                        if (data_dokumen) {
                            let clear_data_data_dokumen = [];
                            var jumlah_dokumen_wajib_isi = 0;
                            $.each(data_dokumen, function (indexInArray, valueOfElement) {
                                clear_data_data_dokumen.push({
                                    penyusunan_dokumen: valueOfElement,
                                    dokumen: valueOfElement.dokumen,
                                    catatan: null,
                                });
                                if (self.dokumen_isian.includes(valueOfElement?.dokumen?.id)) {
                                    jumlah_dokumen_wajib_isi++;
                                }
                            });
                            self.optionsDokumen = clear_data_data_dokumen;
                            self.jumlah_dokumen_wajib_isi = jumlah_dokumen_wajib_isi;
                        }
                        console.log(self.optionsDokumen);
                        self.skk.url = data_edit.skk_url;
                        self.skema_sertifikasi_dokumen.url = data_edit.skema_asertifikasi_dokumen_url;
                        self.skk.nama = data_edit.skk_nama;
                        self.dokumen_all = self.dokumen_isian;
                        (self.preview_surat_tugas = process.env.VUE_APP_BACKEND_URL + "/" + data_edit.path_surat_tugas), console.log(self.skk);
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        getFormDokumenMUK(index, dokumen_id) {
            let self = this;
            self.formPreviewDocMUK = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/preview-dokumen-muk", {
                    params: {
                        penyusunan_id: self.data_penyusunan.id,
                        dokumen_id: dokumen_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.formPreviewDocMUK = res_fix.data.pdf;
                    console.log(res_fix);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });

            self.modalPreviewDokumenMUK = true;
        },

        // Function By proses
        statusValidasiModal(status) {
            let self = this;
            if (status == "tolak") {
                self.showModalValidasi = true;
                self.statusValidasi = status;
            } else if (status == "terima-kehadiran-asesor") {
                self.statusValidasi = status;
                self.storeUbahStatusPenyusunan();
            } else if (status == "submit-ketua-lsp") {
                self.statusValidasi = status;
                self.storeUbahStatusPenyusunan();
            } else {
                self.storeAsesorPenyusun();
                self.statusValidasi = status;
                self.showModalAsesor = true;
            }
        },
        storeUbahStatusPenyusunan() {
            let self = this;
            self.showModalSubmitKetuaLSP = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/ubah-status",
                data: {
                    id: self.id,
                    status: self.statusValidasi,
                    catatan: self.catatan,
                    next_status: self.next_status?.id,
                    password: self.password,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list penyusunan muk segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // close all modal
                            self.showModalValidasi = false;
                            self.showModalAsesor = false;
                            self.modalPasswordKetuaLSP = false;
                            self.modalPassword = false;
                            self.$router.push({ name: "all-daftar_muk" });
                        }
                    });
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
        addAsesor() {
            this.asesor_list.push({
                nama_asesor: null,
            });
        },
        removeAsesor(index) {
            this.asesor_list.splice(index, 1);
            this.disclaimer_asesor_penyusun = false;
            this.storeAsesorPenyusun();
        },
        storeAsesorPenyusun() {
            let self = this;
            self.loading_store_asesor = true;
            self.iframe_pdf_surat_tugas = null;
            let required_form = new Promise(function (resolve, reject) {
                // cek required
                $.each(self.asesor_list, function (indexInArray, valueOfElement) {
                    if (valueOfElement.nama_asesor == null || valueOfElement.nama_asesor == "[]") {
                        Swal.fire({
                            icon: "error",
                            title: "Pastikan daftar asesor yang ditambahkan telah terpilih.",
                        });
                        reject("stop");
                    }
                });
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }
                var config_store_data = {
                    method: "post",
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/store-asesor",
                    data: {
                        id: self.id,
                        asesor_list: JSON.stringify(self.asesor_list),
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };

                axios(config_store_data)
                    .then(function (response) {
                        var res_fix = response.data;
                        console.log(res_fix);
                        self.iframe_pdf_surat_tugas = res_fix.pdf;
                        self.loading_store_asesor = false;
                    })
                    .catch(function (error) {
                        self.axiosCatchError = error.response.data.data.error;
                        Swal.close();
                    });
            });
        },
        verifikasiKehadiran(status_kehadiran, id_item, index) {
            let self = this;
            self.status_kehadiran_asesor = status_kehadiran;
            self.id_kehadiran_asesor = id_item;
            self.index_kehadiran_asesor = index;
            if (status_kehadiran == "Tidak Bersedia") {
                self.showModalStatusKehadiran = true;
            } else {
                self.prosesVerifikasiKehadiran();
            }
        },
        prosesVerifikasiKehadiran() {
            let self = this;
            self.showModalStatusKehadiran = false;
            var index = self.index_kehadiran_asesor;
            var id_kehadiran_asesor = self.id_kehadiran_asesor;
            var keterangan_kehadiran = self.status_kehadiran_asesor;
            self.data_asesor[index].is_loading = true;

            let required_form = new Promise(function (resolve, reject) {
                // cek required
                if (keterangan_kehadiran == "Tidak Bersedia" && (self.catatan_kehadiran == null || self.catatan_kehadiran == "")) {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Formulir Terisi Lengkap",
                    });
                    reject("stop");
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }
                axios
                    .put(
                        process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/ubah-status-kehadiran-asesor",
                        {
                            id_kehadiran_asesor: id_kehadiran_asesor,
                            keterangan_kehadiran: keterangan_kehadiran,
                            notes: self.catatan_kehadiran,
                            penyusunan_muk_id: self.id,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        var res_fix = response.data.data;
                        self.data_asesor[index].is_loading = false;
                        self.data_asesor[index] = res_fix.penyusunan_muk_asesor;
                        self.pdf_surat_tugas = res_fix.pdf?.pdf;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman list penyusunan muk segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                Swal.close();
                                self.$router.push({ name: "all-daftar_muk" });
                            }
                        });
                    })
                    .catch((e) => {
                        this.axiosCatchError = e.response;
                        Swal.close();
                    });
            });
        },
        submitKetuaLSP() {
            let self = this;
            self.showModalSubmitKetuaLSP = true;
            self.getPreviewSuratTugas();
        },
        getPreviewSuratTugas() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            self.iframe_pdf_surat_tugas_fix = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/generate-surat-tugas", {
                    params: {
                        id: self.id,
                        dokumen_surat_tugas: "penyusunan",
                        status_dokumen: "fix",
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.qr_path = res_fix.qr;
                    self.iframe_pdf_surat_tugas_fix = '<iframe src="' + res_fix.pdf + '" width="100%" height="400"></iframe>';
                    console.log(self.qr_path);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        previewSuratTugasAsesor() {
            this.modalSuratTugas = true;
            var path_surat_tugas = process.env.VUE_APP_BACKEND_URL + "/" + this.data_penyusunan.path_surat_tugas;
            this.path_surat_tugas = path_surat_tugas;
        },
        hideModalSubmitKetuaLSP() {
            this.showModalSubmitKetuaLSP = false;
        },
        submitNextStatus() {
            this.next_status = this.data_penyusunan.next_status;
            this.storeUbahStatusPenyusunan();
        },
        pengisianDokumenMUK(index, dokumen_id) {
            let self = this;

            self.form_dokumen_muk = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            self.modalPengisianDokumen = true;
            var penyusunan_dokumen = self.optionsDokumen[index].penyusunan_dokumen;
            console.log(penyusunan_dokumen);
            self.penyusunan_dokumen = penyusunan_dokumen;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/get-form-dokumen-muk", {
                    params: {
                        penyusunan_id: self.data_penyusunan.id,
                        penyusunan_dokumen_id: penyusunan_dokumen.id,
                        dokumen_id: dokumen_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    // var res_fix = response.data.data;
                    console.log(response.data.html);
                    self.form_dokumen_muk = response.data.html;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        storeInputDokumenMUK() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            var form = $("#form-dokumen-muk");
            console.log(self.penyusunan_dokumen);
            var mydata = new FormData(form[0]);
            mydata.append("penyusunan_dokumen_id", self.penyusunan_dokumen?.id);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/store-form-dokumen-muk", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data;
                    console.log(res_data);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Pengisian dokumen MUK berhasil disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    self.modalPengisianDokumen = false;
                    self.getDetailPenyusunan();
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        getPreviewSKK() {
            let self = this;
            self.formPreviewDocMUK = self.skk.url;
            self.modalPreviewDokumenMUK = true;
        },
        getPreviewSkemaSertifikasi() {
            let self = this;
            self.formPreviewDocMUK = self.skema_sertifikasi_dokumen.url;
            self.modalPreviewDokumenMUK = true;
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <b-tabs content-class="p-3 text-muted">
                            <b-tab active class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail Penyusunan</span>
                                </template>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <div class="text-center">
                                        <h3>Formulir Surat Tugas Penyusunan Materi Uji Kompetensi</h3>
                                    </div> -->
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-bordered table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <td style="width: 350px"><strong>Nomor Surat Tugas Penyusunan</strong></td>
                                                            <td>: {{ data_penyusunan.surat_penyusunan_nomor }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Tanggal Surat Tugas Penyusunan</strong></td>
                                                            <td>: {{ data_penyusunan.surat_penyusunan_tanggal }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Perihal</strong></td>
                                                            <td>: {{ data_penyusunan.perihal }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Skema Sertifikasi</strong></td>
                                                            <td>: {{ data_penyusunan.skema_code }} - {{ data_penyusunan.skema_name }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Metode Sertifikasi</strong></td>
                                                            <td>: {{ data_penyusunan.metode_sertifikasi_nama }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Status</strong></td>
                                                            <td>: {{ data_penyusunan.status_nama }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row mt-3" v-if="data_asesor.length > 0">
                                            <div>
                                                <h5>Daftar Asesor Penyusun</h5>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                                    <thead class="bg-dark text-center text-white">
                                                        <tr>
                                                            <th style="width: 5%">No</th>
                                                            <th>Nama Asesor Penyusun</th>
                                                            <th>Status Ketersediaan</th>
                                                            <th>Surat Tugas</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-if="loadingTable">
                                                            <td colspan="3"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                        </tr>
                                                        <tr v-else-if="data_asesor.length == 0">
                                                            <td colspan="4">Asesor Tidak Tersedia</td>
                                                        </tr>
                                                        <tr v-else v-for="(item, index) in data_asesor" :key="index">
                                                            <td class="text-center">
                                                                {{ index + 1 }}
                                                            </td>
                                                            <td>{{ item.asesor_npk }} - {{ item.asesor_nama }}</td>
                                                            <td>
                                                                <div v-if="item.asesor_id == sessionPegawaiId || sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070'">
                                                                    <div v-if="item?.is_loading"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                                                                    <div class="btn-group" v-else-if="item.keterangan_kehadiran == 'Bersedia'">
                                                                        <b-button v-if="data_penyusunan.status_id == '2449b27c-3c0f-11ee-a5ac-0242ac110009' && hak_akses" v-on:click="verifikasiKehadiran('Roleback', item.id, index)" type="button" variant="warning" class="btn-sm">
                                                                            <i class="bx bx-loader"></i>

                                                                            Roleback
                                                                        </b-button>
                                                                        <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                                                    </div>
                                                                    <div class="btn-group" v-else-if="item.keterangan_kehadiran == 'Tidak Bersedia'">
                                                                        <b-button v-if="data_penyusunan.status_id == '2449b27c-3c0f-11ee-a5ac-0242ac110009' && hak_akses" v-on:click="verifikasiKehadiran('Roleback', item.id, index)" type="button" variant="warning" class="btn-sm"><i class="bx bx-loader"></i> Roleback</b-button>
                                                                        <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                                                    </div>
                                                                    <div class="btn-group" v-else>
                                                                        <div v-if="data_penyusunan.status_id == '2449b27c-3c0f-11ee-a5ac-0242ac110009' && hak_akses">
                                                                            <b-button v-on:click="verifikasiKehadiran('Tidak Bersedia', item.id, index)" type="button" variant="danger" class="btn-sm"><i class="fa fa-times"></i> Tidak Bersedia</b-button>
                                                                            <b-button v-on:click="verifikasiKehadiran('Bersedia', item.id, index)" type="button" variant="success" class="btn-sm"><i class="fa fa-check"></i> Bersedia</b-button>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="item.keterangan_kehadiran == 'Tidak Bersedia' && item.notes">Alasan Tidak Bersedia: {{ item.notes }}</div>
                                                                </div>
                                                                <div v-else>
                                                                    <div class="btn-group" v-if="item.keterangan_kehadiran == 'Bersedia'">
                                                                        <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                                                    </div>
                                                                    <div class="btn-group" v-else-if="item.keterangan_kehadiran == 'Tidak Bersedia'">
                                                                        <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                                                    </div>
                                                                    <div class="btn-group" v-else></div>
                                                                    <div v-if="item.keterangan_kehadiran == 'Tidak Bersedia' && item.notes">
                                                                        <br />
                                                                        Alasan Tidak Bersedia: {{ item.notes }}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="btn btn-info btn-sm" v-on:click="previewSuratTugasAsesor"><i class="fa fa-eye"></i> Lihat Surat Tugas</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row" v-if="preview_surat_tugas && data_penyusunan.status_id == 'cb7775b4-3f6d-11ee-bd7c-0242ac110009'">
                                            <h3>Preview Surat Tugas</h3>
                                            <div class="row p-4">
                                                <div class="card" style="outline-style: outset">
                                                    <div class="card-body">
                                                        <iframe v-bind:src="preview_surat_tugas" width="100%" height="400"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12" v-if="data_penyusunan.status_id != '2449b27c-3c0f-11ee-a5ac-0242ac110009'">
                                            <div>
                                                <h5>Daftar Dokumen MUK</h5>
                                                <div class="alert alert-info" v-if="!is_show_dokumen_muk"><i class="fa fa-info"></i> Dokumen MUK akan muncul setelah asesor bersedia</div>
                                            </div>
                                            <table class="table mb-0 table-bordered table-sm mt-2" v-if="is_show_dokumen_muk">
                                                <thead class="bg-dark text-center text-white">
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Dokumen</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="loadingTable">
                                                        <td colspan="3"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                    </tr>
                                                    <tr v-else-if="optionsDokumen.length == 0">
                                                        <td colspan="4">Dokumen Tidak Tersedia</td>
                                                    </tr>
                                                    <tr v-else v-for="(item, index) in optionsDokumen" :key="index">
                                                        <td>
                                                            {{ index + 1 }}
                                                        </td>
                                                        <td>
                                                            {{ item.dokumen?.nama_dokumen_muk }}
                                                        </td>
                                                        <td
                                                            v-bind:class="{
                                                                'bg-warning': dokumen_all.includes(item.dokumen?.id) && item.dokumen?.status_proses_pengisian_id == data_penyusunan.status_id && hak_akses && item.penyusunan_dokumen.isi_dokumen == null,
                                                                'bg-success': dokumen_all.includes(item.dokumen?.id) && item.dokumen?.status_proses_pengisian_id == data_penyusunan.status_id && hak_akses && item.penyusunan_dokumen.isi_dokumen,
                                                            }">
                                                            <button v-if="item.dokumen?.id == 'b88cad66-39dc-11ee-9a36-0242ac110009' || item.dokumen?.id == '4c940cac-3bae-11ee-8a7e-0242ac110009' || item.dokumen?.id == '4c940cac-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSKK()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                                                            <button v-else-if="item.dokumen?.id == '1b5dd154-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSkemaSertifikasi()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                                                            <button v-else type="button" v-on:click="getFormDokumenMUK(index, item.dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>

                                                            <button type="button" v-on:click="pengisianDokumenMUK(index, item.dokumen.id)" class="btn btn-primary btn-sm m-1 rounded-0" v-if="dokumen_all.includes(item.dokumen?.id) && item.dokumen?.status_proses_pengisian_id == data_penyusunan.status_id && hak_akses"><i class="fa fa-eye"></i> Lengkapi Dokumen</button>
                                                        </td>
                                                    </tr>
                                                    <!-- <tr v-if="optionsDokumen.length > 0">
                                                        <td>{{ optionsDokumen.length + 1 }}</td>
                                                        <td>
                                                            {{ skk?.nama }}
                                                        </td>
                                                        <td>
                                                            <button type="button" @click="getPreviewSKK()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                                                        </td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-if="hak_akses">
                                            <div class="row" v-if="data_penyusunan.status_id == '1406ab18-3c0f-11ee-a5ac-0242ac110009'">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end">
                                                        <!-- <b-button v-on:click="statusValidasiModal('tolak')" type="button" variant="danger" class="m-1 btn-sm"><i class="fa fa-times"></i> Tolak</b-button>
                                                        <b-button v-on:click="statusValidasiModal('terima')" type="button" variant="success" class="m-1 btn-sm"><i class="fa fa-check"></i> Terima</b-button> -->
                                                        <b-button v-on:click="statusValidasiModal('terima')" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-else-if="data_penyusunan.status_id == '2449b27c-3c0f-11ee-a5ac-0242ac110009' && hak_akses">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end"></div>
                                                </div>
                                            </div>
                                            <div class="row" v-else-if="data_penyusunan.status_id == '2d1b9b0e-3c0f-11ee-a5ac-0242ac110009'">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end">
                                                        <b-button v-on:click="submitKetuaLSP" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Persetujuan Ketua LSP</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-else-if="data_penyusunan.status_id == '3af2a50e-3f68-11ee-bd7c-0242ac110009' || data_penyusunan.status_id == '7264231e-3f68-11ee-bd7c-0242ac110009'">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end">
                                                        <b-button v-on:click="modalPassword = true" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> E-sign</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-else-if="data_penyusunan.status_id == '35780eae-3c0f-11ee-a5ac-0242ac110009'">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end">
                                                        <b-button v-on:click="submitNextStatus()" v-if="jumlah_dokumen_isi == jumlah_dokumen_wajib_isi" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                        <b-button v-else type="button" variant="primary" class="m-1 btn-sm" disabled><i class="fa fa-save"></i> Proses Submit</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div v-if="data_penyusunan?.status?.slug != 'penyusunan_muk_selesai'">
                                                    <div class="col-md-12 col-md-6" v-if="data_penyusunan?.next_status?.slug == 'penyusunan_muk_selesai'">
                                                        <div class="text-end">
                                                            <b-button v-on:click="submitNextStatus()" type="button" variant="success" class="m-1 btn-sm"><i class="fa fa-save"></i> Selesaikan</b-button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-md-6" v-else>
                                                        <div class="text-end">
                                                            <b-button v-on:click="submitNextStatus()" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3" v-if="data_penyusunan?.status?.slug == 'penyusunan_muk_selesai' && (sessionRoleId == '99d70245-6f47-43b6-98d3-b61579bedce1' || sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070') && !data_penyusunan?.validasi_muk_id">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end">
                                                        <router-link
                                                            :to="{
                                                                name: 'add-surat_tugas_validasi',
                                                                params: { id_penyusunan: id },
                                                            }"
                                                            class="btn btn-sm btn-success bg-success">
                                                            <i class="fas fa-plus"></i>
                                                            Buat Validasi MUK
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab class="border-0" v-if="data_penyusunan.validasi_muk_id">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail Validasi</span>
                                </template>
                                <detail_validasi :penyusunan_id="data_penyusunan.id" :validasi_id="data_penyusunan.validasi_muk_id" :optionsUser="optionsUser" v-if="data_penyusunan.validasi_muk_id"></detail_validasi>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="my-0">Timeline</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style="overflow-x: scroll">
                                    <ul class="timeline timeline-horizontal">
                                        <li class="timeline-item" v-for="(val, key) in data_timeline" :key="key">
                                            <!--v-if-->
                                            <div class="timeline-badge success">
                                                <i class="fa fa-check" style="margin-top: 12px"></i>
                                            </div>
                                            <div class="timeline-panel">
                                                <div class="timeline-heading">
                                                    <b class="timeline-title">{{ val.pejabat_nama }}</b>
                                                    <p>
                                                        <small class="text-muted"><i class="fa fa-clock-o"></i> {{ fullDateTimeFormat(val.created_at) }}</small>
                                                    </p>
                                                </div>
                                                <div class="badge bg-info">{{ val.status_nama }}</div>
                                                <br />
                                                <div class="timeline-body">
                                                    <b>Catatan : </b><br />
                                                    <p>{{ val.notes }}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="modalPreviewDokumenMUK" title="Preview Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
            <div class="row">
                <form @submit.prevent="StorePreviewMUK">
                    <iframe v-bind:src="formPreviewDocMUK" width="100%" height="400"></iframe>
                    <div class="card-footer text-end">
                        <button type="button" class="btn btn-secodary mt-3" @click="modalPreviewDokumenMUK = false"><i class="fas fa-times"></i> Tutup</button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal v-model="showModalValidasi" title="Alasan Penolakan" title-class="text-black font-18" body-class="p-3" hide-footer>
            <div class="row">
                <form @submit.prevent="storeUbahStatusPenyusunan">
                    <div class="form-group">
                        <!-- <label for="">Alasan Penolakan</label> -->
                        <textarea v-model="catatan" id="" cols="30" rows="5" class="form-control"></textarea>
                    </div>
                    <div class="form-group text-end">
                        <button class="btn btn-primary mt-3"><i class="fas fa-save"></i> Simpan</button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal v-model="showModalStatusKehadiran" title="Alasan Tidak Bersedia" title-class="text-black font-18" body-class="p-3" hide-footer>
            <div class="row">
                <form @submit.prevent="prosesVerifikasiKehadiran">
                    <div class="form-group">
                        <!-- <label for="">Alasan Penolakan</label> -->
                        <textarea v-model="catatan_kehadiran" id="" cols="30" rows="5" class="form-control"></textarea>
                    </div>
                    <div class="form-group text-end">
                        <button class="btn btn-primary mt-3"><i class="fas fa-save"></i> Simpan</button>
                    </div>
                </form>
            </div>
        </b-modal>

        <b-modal v-model="showModalAsesor" title="Pilih Asesor Penyusun" size="lg" title-class="text-black font-18" body-class="p-3" hide-footer>
            <div class="row">
                <!-- <form @submit.prevent="storeAsesorPenyusun"> -->
                <div class="form-group">
                    <table class="table table-bordered">
                        <thead class="bg-dark text-light">
                            <tr>
                                <td>Nama Asesor Penyusun</td>
                                <td style="width: 50px">
                                    <div class="btn btn-success btn-sm" v-on:click="addAsesor"><i class="bx bx-plus"></i></div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="asesor_list.length == 0">
                                <td colspan="2">Data Tidak Tersedia</td>
                            </tr>
                            <tr v-else v-for="(val, key) in asesor_list" :key="key">
                                <td>
                                    <v-select :options="optionsUser" label="nama_asesor" v-model="val.nama_asesor" placeholder="Pilih Asesor" @update:modelValue="storeAsesorPenyusun()">
                                        <template v-slot:option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.frekuensi_penyusunan }} Frekuensi Penyusunan</template>
                                        <template v-slot:selected-option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.frekuensi_penyusunan }} Frekuensi Penyusunan</template>
                                    </v-select>
                                </td>
                                <td>
                                    <div class="btn btn-danger btn-sm" v-on:click="removeAsesor(key)"><i class="bx bx-minus"></i></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="form-group">
                        <div v-if="loading_store_asesor"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                        <iframe v-bind:src="iframe_pdf_surat_tugas" width="100%" height="400" v-else></iframe>
                    </div>
                    <div class="form-group" v-if="asesor_list[0]?.nama_asesor != null"><input type="checkbox" v-model="disclaimer_asesor_penyusun" /> Apakah Anda yakin? asesor yang dipilih akan segera mendapat notifikasi penyusunan MUK</div>
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3" v-on:click="storeUbahStatusPenyusunan()" v-if="disclaimer_asesor_penyusun"><i class="fas fa-save"></i> Simpan</button>
                    <button class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
                </div>
                <!-- </form> -->
            </div>
        </b-modal>

        <b-modal v-model="showModalSubmitKetuaLSP" title="Preview Penyusunan MUK" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
            <div class="row">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width: 350px"><strong>Nomor Surat Tugas</strong></td>
                                        <td>: {{ data_penyusunan.surat_penyusunan_nomor }}</td>
                                        <td rowspan="2" v-if="qr_path" style="width: 150px">
                                            <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tanggal Surat Tugas</strong></td>
                                        <td>: {{ data_penyusunan.surat_penyusunan_tanggal }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="form-group">
                        <div v-html="iframe_pdf_surat_tugas_fix"></div>
                    </div>
                    <div class="form-group"><input type="checkbox" v-model="disclaimer_surat_tugas" /> Apakah Anda yakin ingin menerbitkan surat tugas tersebut?</div>
                    <div class="form-group text-end">
                        <button type="button" class="btn btn-secodary mt-3" @click="hideModalSubmitKetuaLSP"><i class="fas fa-times"></i> Tutup</button>
                        <button type="button" class="btn btn-primary mt-3" v-if="disclaimer_surat_tugas" @click="(modalPasswordKetuaLSP = true), (showModalSubmitKetuaLSP = false)"><i class="fas fa-save"></i> Terbitkan</button>
                        <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Terbitkan</button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal v-model="modalSuratTugas" title="Preview Surat Tugas" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
            <div class="row">
                <div class="form-group">
                    <iframe v-bind:src="path_surat_tugas" width="100%" height="400"></iframe>
                </div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="modalSuratTugas = false"><i class="fas fa-times"></i> Tutup</button>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="modalPengisianDokumen" title="Pengisian Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
            <div class="row">
                <form id="form-dokumen-muk">
                    <div class="form-group">
                        <div v-html="form_dokumen_muk"></div>
                    </div>
                </form>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3" v-on:click="storeInputDokumenMUK"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="modalPasswordKetuaLSP" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
            <div class="row">
                <form @submit.prevent="statusValidasiModal('submit-ketua-lsp')">
                    <div class="form-group">
                        <input type="password" required class="form-control" v-model="password" placeholder="Password Anda" />
                    </div>
                    <div class="form-group text-end">
                        <button class="btn btn-primary mt-3" type="submit"><i class="fas fa-save"></i> Konfirmasi</button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal v-model="modalPassword" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
            <div class="row">
                <form @submit.prevent="submitNextStatus">
                    <div class="form-group">
                        <!-- <label for="">Alasan Penolakan</label> -->
                        <input type="password" class="form-control" required v-model="password" placeholder="Password Anda" />
                    </div>
                    <div class="form-group text-end">
                        <button class="btn btn-primary mt-3" type="submit"><i class="fas fa-save"></i> Konfirmasi</button>
                    </div>
                </form>
            </div>
        </b-modal>
    </Layout>
</template>
